function statistic(path) {
  (function () {
	console.log('statistic ready')
	$(document).ready(function () {
		console.log('document ready')
		var fhsite = (window.location.hostname=='cepetdapet.com' || window.location.hostname=='www.cepetdapet.com') ? '1':'2'
		console.log('id_site : ',fhsite)

		var mataomourl = fhsite=='1' ? 'https://metric-ceda.com/matomo/' : '//178.128.112.230/matomo/'

		console.log('m_url : ',mataomourl)
		var _paq = window._paq = window._paq || [];
		/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
	
		var getuserdata = localStorage.getItem("USERDATA")
		if (getuserdata !== null) {
			var userdata = JSON.parse(getuserdata);
			_paq.push(['setUserId', userdata.id]);
			console.log('setUserId : ',userdata.id)
		}
		
		_paq.push(['setDocumentTitle', document.title]);

		// //... causing utm not working.. commented
		// _paq.push(["setCustomUrl", path]);

        // var templateElement = document.getElementById('templateId');
        // if (templateElement) {
		// var idTemp =  templateElement.value;
		// // send value 'idTemp' to matomo roundrobin 1/2/3
		// console.log('idTemp : ',idTemp);
		// _paq.push(['setDocumentTitle', 'Template Landing Page '+idTemp+'']);
		// _paq.push(['setCustomVariable', 1, 'ABTest', 'Template Landing Page '+idTemp+'', 'visit']);
		// }


		_paq.push(['trackPageView']);
		_paq.push(['enableLinkTracking']);
		(function() {
			
		var u= mataomourl;
		  _paq.push(['setTrackerUrl', u+'matomo.php']);
		  _paq.push(['setSiteId', fhsite]);
		  var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
		  g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
		})();

	// Matomo Tag Manager 

		if(fhsite=='2'){
			//dev
			var _mtm = window._mtm = window._mtm || [];
			_mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
			var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
			g.async=true; g.src=mataomourl+'js/container_9yFYDdcK.js'; s.parentNode.insertBefore(g,s);
		} else {
			//prod
			var _mtm = window._mtm = window._mtm || [];
			_mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
			var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
			g.async=true; g.src=mataomourl+'js/container_VBbWagCi.js'; s.parentNode.insertBefore(g,s);
		}

	})

    // const u = "//MY_URL/";

    // // Remove the script added before
    // const d = document, g = d.createElement("script"), s = d.getElementsByTagName("script")[0];
    // const sSrc = s.getAttribute('src');
    // if (sSrc === u + "matomo.js") {
    //   s.remove();
    // }

    // // Set variables
    // const _paq = window._paq = window._paq || [];
    // _paq.push(["disableCookies"]);
    // _paq.push(["setCustomUrl", path]);
    // _paq.push(["trackPageView"]);

    // (function () {
    //   _paq.push(["setTrackerUrl", u + "matomo.php"]);
    //   _paq.push(["setSiteId", "1"]);
    //   const d = document, g = d.createElement("script"), s = d.getElementsByTagName("script")[0];
    //   g.type = "text/javascript";
    //   g.async = true;
    //   g.src = u + "matomo.js";
    //   s.parentNode.insertBefore(g, s);
    // })();















	// var fhsite = (window.location.hostname=='cepetdapet.com' || window.location.hostname=='www.cepetdapet.com') ? '1':'2'
	// console.log('id_site : ',fhsite)
	// var mataomourl = (window.location.hostname=='cepetdapet.com' || window.location.hostname=='www.cepetdapet.com') ? 'https://metric-ceda.com/matomo/':'//178.128.112.230/matomo/'
	// console.log('m_url : ',mataomourl)

	// // const u = "//MY_URL/";
	// const u = mataomourl;

    // // Remove the script added before
    // const d = document, g = d.createElement("script"), s = d.getElementsByTagName("script")[0];
	// console.log('get g ',g)
	// console.log('get s ',s)
    // const sSrc = s.getAttribute('src');
	// console.log('get sSrc ',sSrc)
    // if (sSrc === u + "matomo.js") {
    //   s.remove();
	//   console.log('remove prev matomo.js')
    // }

    // // Set variables
    // const _paq = window._paq = window._paq || [];
	// var getuserdata = localStorage.getItem("USERDATA")
	// if (getuserdata !== null) {
	// 	var userdata = JSON.parse(getuserdata);
	// 	_paq.push(['setUserId', userdata.id]);
	// 	console.log('setUserId : ',userdata.id)
	// }
	
	// _paq.push(['trackPageView']);
	// _paq.push(['enableLinkTracking']);

	// console.log('matomo push trackPageView');

    // (function () {

	// 	// _paq.push(['setTrackerUrl', u+'matomo.php']);
	// 	// _paq.push(['setSiteId', fhsite]);
	// 	// var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
	// 	// g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);

    //   _paq.push(["setTrackerUrl", u + "matomo.php"]);
    //   _paq.push(["setSiteId", fhsite]);
    //   const d = document, g = d.createElement("script"), s = d.getElementsByTagName("script")[0];
    //   g.type = "text/javascript";
    //   g.async = true;
    //   g.src = u + "matomo.js";
    //   s.parentNode.insertBefore(g, s);

	//   console.log('matomo push setSiteId')

	//   var _mtm = window._mtm = window._mtm || [];
	//   _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
	//   if(window.location.hostname!=='cepetdapet.com'  || window.location.hostname!=='www.cepetdapet.com'){
	// 	  //dev
	// 	  g.async=true; g.src=mataomourl+'js/container_9yFYDdcK.js'; s.parentNode.insertBefore(g,s);
	// 	  console.log('matomo push container_9yFYDdcK dev')
	//   } 
	//   else {
	// 	  //prod
	// 	  g.async=true; g.src=mataomourl+'js/container_VBbWagCi.js'; s.parentNode.insertBefore(g,s);
	// 	  console.log('matomo push container_VBbWagCi prod')
	//   }

    // })();

















    // -----------  Matomo ------------ //

	// var fhsite = (window.location.hostname=='cepetdapet.com' || window.location.hostname=='www.cepetdapet.com') ? '1':'2'
	// console.log('id_site : ',fhsite)
	// var mataomourl = (window.location.hostname=='cepetdapet.com' || window.location.hostname=='www.cepetdapet.com') ? 'https://metric-ceda.com/matomo/':'//178.128.112.230/matomo/'
	// console.log('m_url : ',mataomourl)
	// var _paq = window._paq = window._paq || [];
	// /* tracker methods like "setCustomDimension" should be called before "trackPageView" */

	// var getuserdata = localStorage.getItem("USERDATA")
	// if (getuserdata !== null) {
	// 	var userdata = JSON.parse(getuserdata);
	// 	_paq.push(['setUserId', userdata.id]);
	// 	console.log('setUserId : ',userdata.id)
	// }
	

	// _paq.push(['trackPageView']);
	// _paq.push(['enableLinkTracking']);
	// (function() {
		
	// //   var u="//178.128.112.230/matomo/";
	// var u= mataomourl;
	//   _paq.push(['setTrackerUrl', u+'matomo.php']);
	//   _paq.push(['setSiteId', fhsite]);
	//   var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
	//   g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
	// })();

    // // -----------  Matomo Tag Manager ------------ //
    // var mataomourl = (window.location.hostname=='cepetdapet.com' || window.location.hostname=='www.cepetdapet.com') ? 'https://metric-ceda.com/matomo/':'//178.128.112.230/matomo/'
	// if(window.location.hostname!=='cepetdapet.com'  || window.location.hostname!=='www.cepetdapet.com'){
	// 	//dev
	// 	var _mtm = window._mtm = window._mtm || [];
	// 	_mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
	// 	var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
	// 	g.async=true; g.src=mataomourl+'js/container_9yFYDdcK.js'; s.parentNode.insertBefore(g,s);
	// } else {
	// 	//prod
	// 	var _mtm = window._mtm = window._mtm || [];
	// 	_mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
	// 	var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
	// 	g.async=true; g.src=mataomourl+'js/container_VBbWagCi.js'; s.parentNode.insertBefore(g,s);
	// }



  })();
}